import { resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
export default {
  name: "rideRecord",
  mixins: [mixins],
  data() {
    return {
      dialogVisible: false,
      refundDialogVisible: false,
      isAdd: true,
      map: null,
      AMap: null,
      currentRecord: null,
      parkFeeDialogVisible: false,
      parkFeeModel: {
        reason: '',
        parkFee: 0
      },
      src: '',
      columns: [{
        type: 'expand',
        typeSlot: 'logExpand'
      }, {
        label: '机构',
        prop: 'orgName'
      }, {
        label: '用户',
        prop: 'phone'
      }, {
        label: '开始时间',
        prop: 'beginTime',
        width: '170px'
      }, {
        label: '结束时间',
        prop: 'endTime',
        width: '170px'
      }, {
        label: '总费用',
        prop: 'rideFee'
      }, {
        label: '已付费用',
        prop: 'paidFee'
      }, {
        label: '骑行时长',
        prop: 'durations'
      }, {
        label: '骑行里程',
        prop: 'mileage'
      }, {
        label: '支付状态',
        prop: 'payState'
      }, {
        label: '轨迹',
        customRender: props => {
          return _createVNode(_resolveComponent("el-link"), {
            "type": "primary",
            "underline": false,
            "onClick": () => {
              this.showGPS(props.row);
            }
          }, {
            default: () => [_createTextVNode("\u67E5\u770B"), _createVNode("i", {
              "className": "el-icon-view el-icon--right"
            }, null)]
          });
        }
      }],
      topButtons: [{
        name: '导出',
        code: 'parkRecord_export',
        type: 'primary',
        click: this.inputInfo,
        permissions: ['parkRecord_export']
      }],
      linkButtons: [],
      // 搜索字段
      searchItems: [{
        prop: 'phone',
        label: '手机号码'
      }, {
        prop: 'payState',
        label: '支付状态',
        type: 'select',
        items: [{
          name: "未支付",
          id: 0
        }, {
          name: "已支付",
          id: 2
        }, {
          name: "部分支付",
          id: 1
        }, {
          name: "免费",
          id: 3
        }],
        itemProp: {
          label: 'name',
          value: 'id'
        }
      }, {
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }],
      searchItemsRules: {
        time: [{
          required: true,
          message: '请选择时间范围',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    loadLog(item) {
      if (!item.id) {
        return;
      }
      this.$api.business.rideRecord.getLogs({
        id: item.id,
        parkRecordTime: item.beginTime
      }).then(res => {
        if (res.code === 200) {
          item.logs = res.data;
        }
      });
      return '加载中';
    },
    showGPS(row) {
      this.$router.push({
        path: '/gps',
        query: {
          recordId: row.id,
          beginTime: row.beginTime
        }
      });
    }
  }
};